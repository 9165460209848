/*
* 周期的に動態情報を取得する
*
* */

import { URL_ROOT } from "../urls";
import { cellToLatLng } from "h3-js";
import { useAuthSWR } from "../hooks/useAuthSWR";
import {useAppDispatch} from "../states/hooks";
import {setUpdateLatestMil} from "../states/UpdateTimingSlice";
import {useEffect} from "react";

export type Reservation = {
    lat: number,
    lon: number,
    reserve_mil: number,
}


export function useReservation() {
    const url = URL_ROOT + "get-reservations"
    const { data, error, isLoading } = useAuthSWR(url)
    const dispatch = useAppDispatch()

    let reservations = []
    let update_mil = null

    useEffect(() => {data !== undefined && data !== null && dispatch(setUpdateLatestMil(data["update_mil"]))}, [data, isLoading])

    if (data !== undefined && data !== null) {
        update_mil = data["update_mil"]

        for (let p of data["data"]) {
            let [lat, lon] = cellToLatLng(p.h3_code)
            let r: Reservation = { lat: lat, lon: lon, reserve_mil: p.reserve_mil }
            reservations.push(r)
        }
    }

    return {
        reservations: reservations,
        update_mil: update_mil,
        isLoading: isLoading,
        isError: error,
    }
}

import {configureStore} from '@reduxjs/toolkit'
import mapAvailableReducer from "./MapAvailableSlice";
import updateTimingSlice from "./UpdateTimingSlice";

const store = configureStore({
    reducer: {
        mapAvailables: mapAvailableReducer,
        updateTiming: updateTimingSlice,
    }
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;

/*地図にどの情報を表示するかを制御する*/
import {setShowProbes, setShowClusteredReserves, setShowDemands} from "./states/MapAvailableSlice";
import {useAppDispatch} from "./states/hooks";
import React, {useState} from "react";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";

export default function MapShowSelector() {
    const dispatch = useAppDispatch()
    const [alignment, setAlignment] = useState([] as string[])

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string[],
    ) => {
        setAlignment(newAlignment);
        let hasClusteredReserve = false
        for (let s of newAlignment) {
            if (s === "clustered_reserve") {
                hasClusteredReserve = true
            }
        }

        let hasProbe = false
        for (let s of newAlignment) {
            if (s === "probe") {
                hasProbe = true
            }
        }

        let hasDemand = false
        for (let s of newAlignment) {
            if (s === "demand") {
                hasDemand = true
            }
        }
        dispatch(setShowClusteredReserves(hasClusteredReserve))
        dispatch(setShowProbes(hasProbe))
        dispatch(setShowDemands(hasDemand))
    };

    let buttonStyle = {width:"100%", height:"100px", fontSize: 30}

    return <>
        <ToggleButtonGroup
            color="success"
            value={alignment}
            onChange={handleChange}
            aria-label="Platform"
            orientation={"vertical"}
            sx={{width:"150px"}}
        >
            <ToggleButton sx={buttonStyle} value="clustered_reserve">予約</ToggleButton>
            <ToggleButton sx={buttonStyle} value="probe">動態</ToggleButton>
            <ToggleButton sx={buttonStyle} value="demand">需給</ToggleButton>
        </ToggleButtonGroup>
    </>
}

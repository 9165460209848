/*最終更新時刻を表示するためのモジュール*/


import React from 'react';
import {useAppSelector} from "./states/hooks";
import {date2dayTimeJST} from "./utils";
import {Box, Typography} from "@mui/material";

export default function UpdateLatest() {
    const {updateLatestMil} = useAppSelector((state) => state.updateTiming)

    let message = null;
    if (updateLatestMil !== null && updateLatestMil !== undefined) {
        try {
            let timing = new Date(Number(updateLatestMil));
            message = "更新 " + date2dayTimeJST(timing);
        } catch (e) {
            console.log("error", e);
        }
    }

    return <Box display="flex" sx={{alignItems: "center", justifyContent: "center", marginTop: "20px"}}>
        <Typography>{message} </Typography>
    </Box>
}

import useSWR, { SWRConfiguration } from "swr";
import { SWRResponse } from "swr";
import { useLocation, useNavigate } from "react-router-dom";

import Cookies from "js-cookie";

const SESSION_COOKIE_NAME = "SESSION_TOKEN";

interface Session {
    sessionID: string;
}

export function getSessionID(): Session | null {
    const json = Cookies.get(SESSION_COOKIE_NAME);
    if (json) {
        return JSON.parse(json) as Session;
    }
    return null;
}

export function setSessionID(sessionID: string) {
    const json = JSON.stringify({ sessionID: sessionID } as Session);
    Cookies.set(SESSION_COOKIE_NAME, json, { expires: 1 });
}

export function revokeSession() {
    Cookies.remove(SESSION_COOKIE_NAME);
}

export function appendAuthenticateFetchHeader(headers: Headers) {
    const token = getSessionID();
    if (token) {
        headers.append("Authorization", "Bearer " + token.sessionID);
    }
}

class UnauthorizedError extends Error { };

export async function fetcher<T>(url: string): Promise<T> {
    const headers = new Headers();
    appendAuthenticateFetchHeader(headers);

    const res = await fetch(url, { headers, mode: "cors" });
    if (res.status === 403 || res.status === 401) {
        throw new UnauthorizedError(await res.text());
    }
    if (res.status === 500) {
        throw new Error("server error");
    }
    return await res.json() as T;
}

export function useAuthSWR<D = any, E = any>(key: string, config?: SWRConfiguration): SWRResponse<D, E> {
    const res = useSWR<D, E>(key, fetcher, config);
    const navigate = useNavigate();
    const location = useLocation();

    if (res.error instanceof UnauthorizedError && location.pathname !== "/login") {
        const path = "/login?redirect=" + encodeURIComponent(location.pathname);
        navigate(path);
    }

    return res;
}

import "./static/App.css";
import * as React from "react";
import { VehicleMap } from "./VehicleMap";
import { Stack } from "@mui/material";
import MapShowSelector from "./MapShowSelector";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SWRConfig } from "swr";
import useUser from "./hooks/useUser";
import UpdateLatest from "./UpdateLatest";

function App() {
    useUser();

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SWRConfig
                    value={{
                        // デフォルトの更新周期を3分にする
                        refreshInterval: 3 * 60 * 1000,
                        fetcher: (resource) =>
                            fetch(resource, { mode: "cors" }).then((res) =>
                                res.json()
                            ),
                    }}
                >
                    <Stack direction={"row"}>
                        <VehicleMap />
                        <Stack direction={"column"}>
                            <MapShowSelector />
                            <UpdateLatest />
                        </Stack>
                    </Stack>
                </SWRConfig>
            </LocalizationProvider>
        </>
    );
}

export default App;

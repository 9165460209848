import {Source, Layer} from 'react-map-gl'
import {useAppSelector} from "../states/hooks";
import {useDemand} from "../fetchers/DemandFetcher";


export function DemandLayer() {
    let {demands} = useDemand()

    let requests = demands.map( (demand) => {return demand.request_cnt})
    requests.sort((a, b) => {return b - a})
    let class1_req = 999999999;
    let class2_req = 999999999;
    let class3_req = 999999999;
    // 上位5件, 10件, 15件のリクエスト数を持つところをハイライト
    let unit_req = 5;
    if (requests.length >= unit_req) {
        class1_req = requests[unit_req-1]
    }
    if (requests.length >= unit_req*2) {
        class2_req = requests[unit_req*2-1]
    }
    if (requests.length >= unit_req*3) {
        class3_req = requests[unit_req*3-1]
    }


    const features = demands.map( (demand) => {
        let coordinates = []
        for (let p of demand.poly) {
            coordinates.push([p[1], p[0]])
            //coordinates.push([p[0], p[1]])
        }

        return {
            //type: 'Feature', geometry: {type: 'Point', coordinates: [vehicle.lon, vehicle.lat]},
            type: 'Feature', geometry: {type: 'Polygon', coordinates: [coordinates]},
            properties: {
                ride_rate: demand.ride_rate,
                request_cnt: demand.request_cnt,
            }
        };
    });

    const geo = {
        type: 'FeatureCollection',
        features: features
    }

    /*
                   'fill-color': [
                        'case',
                        ['>', ['get', 'ride_rate'], 0.9], 'rgba(0,19,204,0.15)',
                        ['>', ['get', 'ride_rate'], 0.8], 'rgba(250,209,27, 0.15)',
                        'rgba(255,0,0,0.15)'
                    ],
    * */

    return <>
        <Source type="geojson" id="my-demands" data={geo as any}>
            <Layer id="demand_rate" type="fill"
                paint={{
                    'fill-color': [
                        'case',
                        ['>=', ['get', 'request_cnt'], class1_req], 'rgba(255,0,0,0.15)',
                        ['>=', ['get', 'request_cnt'], class2_req], 'rgba(243,220, 69, 0.3)',
                        ['>=', ['get', 'request_cnt'], class3_req], 'rgba(0,19,204,0.15)',
                        'rgba(255,0,0,0)'
                    ],
                }}
                filter={['>=', ['get', 'request_cnt'], class3_req]}
            />
        </Source>
    </>
}

import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    updateLatestMil: null,
}

const updateTimingSlice = createSlice({
    name: 'mapAvailable',
    initialState,
    reducers: {
        setUpdateLatestMil(state, action) {
            if (state.updateLatestMil === null || state.updateLatestMil < action.payload) {
                state.updateLatestMil = action.payload;
            }
        },
    },
})

export const {setUpdateLatestMil} = updateTimingSlice.actions
export default updateTimingSlice.reducer

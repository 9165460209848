import React from "react";
import Map, {Source, Layer} from 'react-map-gl'
import {useAppSelector} from "./states/hooks";
import premiumArea from "./samples/premium_area.json"
import {ProbeLayer} from "./map_layers/ProbeLayer";
import {ReserveClusterLayer} from "./map_layers/ReserveClusterLayer";
import {DemandLayer} from "./map_layers/DemandLayer";
import {GpsLayer} from "./map_layers/GpsLayer";

export function VehicleMap() {
    const {showProbes, showClusteredReserves, showDemands} = useAppSelector((state) => state.mapAvailables)
    let lat = 35.68400
    let lon = 139.7598

    return (
        <Map
            initialViewState={{
                longitude: lon,
                latitude: lat,
                zoom: 13
            }}
            style={{height: "100vh"}}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            mapStyle="mapbox://styles/japantaxi-sw/cljf0j8mb001p01r49imy55c6"
            dragRotate={false}
        >
            { showProbes && <ProbeLayer /> }
            { showClusteredReserves && <ReserveClusterLayer /> }
            { showDemands && <DemandLayer /> }
            <GpsLayer />

            <Source type="geojson" id="premium-area" data={premiumArea as any}>
                <Layer id='premium_area' type='line' paint={{"line-color":"#a9e8f5"}} />
            </Source>
        </Map>
    );
}

/*
* 周期的に動態情報を取得する
*
* */

import { URL_ROOT } from "../urls"
import { useAuthSWR } from "../hooks/useAuthSWR";
import {useAppDispatch} from "../states/hooks";
import {setUpdateLatestMil} from "../states/UpdateTimingSlice";
import {useEffect} from "react";

export type Probe = {
    lat: number,
    lon: number,
}



export function useProbe() {
    const url = URL_ROOT + "get-probes"
    const { data, error, isLoading } = useAuthSWR(url)
    const dispatch = useAppDispatch()

    let probes = []
    let update_mil = null

    useEffect(() => {data !== undefined && data !== null && dispatch(setUpdateLatestMil(data["update_mil"]))}, [data, isLoading])

    if (data !== undefined && data !== null) {
        update_mil = data["update_mil"]

        for (let p of data["data"]) {
            let newProbe = {
                lat: p.lat,
                lon: p.lon,
            };
            probes.push(newProbe)
        }
    }

    return {
        probes: probes,
        update_mil: update_mil,
        isLoading: isLoading,
        isError: error,
    }
}

/*
* 周期的に動態情報を取得する
*
* */

import { URL_ROOT } from "../urls";
import { cellToBoundary } from "h3-js";
import { useAuthSWR } from "../hooks/useAuthSWR";
import {useAppDispatch} from "../states/hooks";
import {setUpdateLatestMil} from "../states/UpdateTimingSlice";
import {useEffect} from "react";

export type Demand = {
    poly: any,
    ride_rate: number, // 乗車率
    request_cnt: number, // リクエスト数
}


export function useDemand() {
    const url = URL_ROOT + "get-demands"
    const { data, error, isLoading } = useAuthSWR(url)
    const dispatch = useAppDispatch()

    let demands = []
    let update_mil = null

    useEffect(() => {data !== undefined && data !== null && dispatch(setUpdateLatestMil(data["update_mil"]))}, [data, isLoading])

    if (data !== undefined && data !== null) {
        update_mil = data["update_mil"]

        for (let p of data["data"]) {
            let poly = cellToBoundary(p.h3_code)
            demands.push({ poly: poly, ride_rate: p.rate, request_cnt: p.cnt })
        }
    }

    return {
        demands: demands,
        update_mil: update_mil,
        isLoading: isLoading,
        isError: error,
    }
}

import { useNavigate } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { URL_ROOT } from "./urls";
import {
    appendAuthenticateFetchHeader,
    setSessionID,
} from "./hooks/useAuthSWR";
import { mutate } from "swr";
import Cookies from "js-cookie";
import {
    Alert,
    Box,
    Button,
    FormGroup,
    TextField,
    Typography,
} from "@mui/material";

enum FormState {
    inputUserID = 0,
    submittingUserID,
    inputOTP,
    submittingOTP,
}

interface RequestOTPSuccessResponse {
    session_id: string;
}

interface FailedResponse {
    detail: string;
}

const USER_ID_COOKIE_NAME = "KAGOSHIMA_DEFAULT_USER_ID";

function setDefaultUserID(userID: string) {
    localStorage.setItem(USER_ID_COOKIE_NAME, userID);
}

function getDefaultUserID() {
    let name = localStorage.getItem(USER_ID_COOKIE_NAME)
    if (name === null || name === undefined) {
        name = "";
    }
    return name;
}


export default function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const [formState, setFormState] = useState<FormState>(
        FormState.inputUserID
    );
    const [userID, setUserID] = useState(getDefaultUserID());
    const [otp, setOTP] = useState("");
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const inputForm = useRef<HTMLInputElement>();

    useEffect(() => {
        if (inputForm.current) {
            inputForm.current.focus();
        }
    });

    const submitUserID = async () => {
        setErrMsg(null);
        if (userID === "") {
            return;
        }

        setFormState(FormState.submittingUserID);

        const params = new FormData();
        params.append("user_id", userID);
        setDefaultUserID(userID);

        const res = await fetch(`${URL_ROOT}request_otp`, {
            method: "POST",
            body: params,
        });
        switch (res.status) {
            case 200:
                break;
            case 401:
                setErrMsg(
                    "認証できませんでした。入力に誤りがある可能性があります。再度やり直してください。"
                );
                setFormState(FormState.inputUserID);
                return;
            default:
                setErrMsg("サーバで問題が発生しました。");
                setFormState(FormState.inputUserID);
                return;
        }
        const resBody = (await res.json()) as RequestOTPSuccessResponse;
        setSessionID(resBody.session_id);
        setFormState(FormState.inputOTP);
        setOTP("");
        setUserID("");
    };

    const submitOTP = async () => {
        setErrMsg(null);
        if (otp === "") {
            return;
        }

        setFormState(FormState.submittingOTP);

        const params = new FormData();
        params.append("otp_code", otp);
        const headers = new Headers();
        appendAuthenticateFetchHeader(headers);
        const res = await fetch(`${URL_ROOT}authorize_otp`, {
            method: "POST",
            body: params,
            headers,
        });
        switch (res.status) {
            case 200:
                break;
            case 401: {
                const resBody = (await res.json()) as FailedResponse;
                setErrMsg(
                    "認証できませんでした。入力に誤りがある可能性があります。再度やり直してください。"
                );
                // OTP 誤り以外は最初の入力からやり直す
                if (resBody.detail === "otp not match") {
                    setFormState(FormState.inputOTP);
                } else {
                    setOTP("");
                    setFormState(FormState.inputUserID);
                }

                return;
            }
            default:
                setErrMsg("サーバで問題が発生しました。");
                setFormState(FormState.inputUserID);
                return;
        }
        await mutate(() => true);
        const urlParams = new URLSearchParams(location.search);
        const redirect_url = urlParams.get("redirect_uri");
        if (redirect_url == null) {
            navigate("/");
        } else {
            navigate(redirect_url);
        }

        setOTP("");
    };

    let id;
    let label;
    let value;
    let disabled;
    let onChange;
    let disabledButton;
    let buttonOnClick;
    let buttonMessage;

    if (
        formState === FormState.inputUserID ||
        formState === FormState.submittingUserID
    ) {
        id = "user_id";
        label = "端末電話番号";
        value = userID;
        disabled = formState !== FormState.inputUserID;
        onChange = (e: any) => {
            setUserID(e.target.value);
        }
        disabledButton = formState !== FormState.inputUserID;
        buttonOnClick = submitUserID;
        buttonMessage = "ログイン"
    } else {
        id = "otp";
        label="ワンタイムパスワード";
        value = otp;
        disabled= formState !== FormState.inputOTP;
        onChange = (e: any) => {
            setOTP(e.target.value);
        }
        disabledButton = formState !== FormState.inputOTP;
        buttonOnClick = submitOTP;
        buttonMessage = "送信"
    }

    let alertStyle = {fontSize:30, height:"80px"}

    return (
        <Box display="flex" justifyContent={"center"} alignItems={"center"} minHeight="100vh" maxWidth={"100vw"} sx={{flexDirection: 'column'}}>
            <Typography
                variant="h3"
                sx={{
                    mt: "10px",
                    mb: 6,
                    textAlign: "center",
                }}
            >
                Wood Garden ログイン
            </Typography>
            <FormGroup sx={{width: "70%"}}>
                <TextField
                    autoFocus
                    id={id}
                    label={label}
                    variant="outlined"
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    inputRef={inputForm}
                    sx={{height: "80px", mb: "30px", width:"100%"}}
                    inputProps={{style: {fontSize: 25}}} // font size of input text
                    InputLabelProps={{style: {fontSize: 25}}} // font size of input label
                />
                <Button
                    variant="contained"
                    type="submit"
                    disabled={disabledButton}
                    onClick={buttonOnClick}
                    sx={{fontSize: 40, mb: "30px"}}
                >
                    {buttonMessage}
                </Button>

                {errMsg ? <Alert severity="error" sx={alertStyle}>{errMsg}</Alert> : <Box sx={alertStyle}/>}
            </FormGroup>
        </Box>
    );
}

import {Source, Layer} from 'react-map-gl'
import {useProbe} from "../fetchers/ProbeFetcher";


export function ProbeLayer() {
    let {probes, update_mil, isError, isLoading} = useProbe()

    const features = probes.map( (vehicle) => {
        return {
            type: 'Feature', geometry: {type: 'Point', coordinates: [vehicle.lon, vehicle.lat]},
        };
    });

    const geo = {
        type: 'FeatureCollection',
        features: features
    }

    return <>
        <Source type="geojson" id="my-vehicle" data={geo as any}>
            <Layer id="vehicle_point" type="circle"
                paint={{
                    'circle-radius': 5,
                    'circle-stroke-width': 12,
                    'circle-stroke-opacity': 0.001,
                    'circle-color': "rgba(68,120,201,0.76)",
                }}
            />
        </Source>
    </>
}
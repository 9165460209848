/*GPSの位置情報を取得するモジュール*/



import {useGeolocated} from "react-geolocated";

export function useGPS() {
    const {coords, isGeolocationAvailable, isGeolocationEnabled} = useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        watchPosition: true,
        // 3分間GPSの位置情報を取得できなかったらエラーを返す
        userDecisionTimeout: 3 * 60 * 1000,
        //userDecisionTimeout: 5000,
    })

    if (isGeolocationAvailable && isGeolocationEnabled && coords !== undefined && coords !== null) {
        return {
            lat: coords.latitude,
            lon: coords.longitude,
        }
    } else {
        return {
            lat: null,
            lon: null,
        }
    }

}

import {Source, Layer} from 'react-map-gl'
import {date2timeJST} from "../utils";
import {useReservation} from "../fetchers/ReservationFetcher";


export function ReserveClusterLayer() {
    let {reservations, update_mil, isLoading, isError} = useReservation()
    let currentMil = new Date().getTime()

    let newReserves = []
    for (let d of reservations) {
        if (d.reserve_mil >= currentMil) {
            newReserves.push(d)
        }
    }
    reservations = newReserves

    const features = reservations.map( (r) => {
        let text = `${date2timeJST(new Date(r.reserve_mil))}`

        return {
            type: 'Feature', geometry: {type: 'Point', coordinates: [r.lon, r.lat]},
            properties: {
                reserve_time: text,
                reserve_mil: r.reserve_mil,
            }
        };
    });

    const geo = {
        type: 'FeatureCollection',
        features: features
    }


    return <>
        <Source type="geojson" id="reserves" data={geo as any} cluster={true}
                clusterProperties={{
                    "reserved_time": [
                        ["case",
                            ["<", ["accumulated"], ["get", "reserved_time"]], ["accumulated"],
                            ["get", "reserved_time"]],
                        ["get", "reserve_time"]
                    ]
                }}
                clusterRadius={50}

            >
            <Layer id='clustered_reserves' type='symbol'
                   filter={['==', 'cluster', true]}
                   layout={{
                       "icon-image": "embassy",
                       "icon-anchor": "bottom",
                       "icon-allow-overlap": true,
                       "text-allow-overlap": true,
                       "text-ignore-placement": true,
                       "icon-size": 2.0,
                       "text-offset": [0, -2.6],
                       "text-field": ['concat', ['get', 'reserved_time'], " x", ['get', 'point_count']],
                   }}
                   paint={{
                       "icon-color": "red",
                       "text-color": "#6707e7",
                   }}
            />
            <Layer id='unclustered_reserves' type='symbol'
                   filter={['!=', 'cluster', true]}
                   layout={{
                       "icon-image": "embassy",
                       "icon-anchor": "bottom",
                       "icon-allow-overlap": true,
                       "text-allow-overlap": true,
                       "text-ignore-placement": true,
                       "icon-size": 2.0,
                       "text-offset": [0, -2.6],
                       "text-field": ['get', 'reserve_time'],
                   }}
                   paint={{
                       "icon-color": "red",
                       "text-color": "#6707e7",
                   }}
            />

        </Source>
    </>
}

import {Source, Layer} from 'react-map-gl'
import {useProbe} from "../fetchers/ProbeFetcher";
import {useGPS} from "../fetchers/GpsFetcher";


export function GpsLayer() {
    let {lat, lon} = useGPS()

    const features = []

    if (lat !== null && lon !== null) {
        features.push({
            type: 'Feature', geometry: {type: 'Point', coordinates: [lon, lat]},
        });
    }

    const geo = {
        type: 'FeatureCollection',
        features: features
    }

    return <>
        <Source type="geojson" id="my-gps" data={geo as any}>
            <Layer id='gps_point' type='symbol'
                   layout={{
                       "icon-image": "car",
                       "icon-anchor": "bottom",
                       "icon-size": 2.0,
                   }}
            />

        </Source>
    </>
}

import {format, formatInTimeZone, utcToZonedTime} from 'date-fns-tz'


/*時刻の文字列表現を得る
* => 2022-12-01 00:00:00
* のような表現に変換する。(UTC前提)
* */
export function date2dayTimeUTC(d: Date) : string {
    const s2 = formatInTimeZone(d, 'UTC', 'yyyy-MM-dd HH:mm:ss')
    return s2
}

export function date2dayTimeJST(d: Date) : string {
    const s2 = formatInTimeZone(d, 'JST', 'MM-dd HH:mm:ss')
    return s2
}


export function date2dayJST(d: Date) : string {
    const s2 = formatInTimeZone(d, 'JST', 'yyyy-MM-dd')
    return s2
}

export function date2timeJST(d: Date) {
    const jst = utcToZonedTime(d, 'Asia/Tokyo')
    const s = format(jst, 'HH:mm')
    return s;
}

/*
* Apiから受け取ったTimestamp型はnullのときに、NaTという文字列が入ってくる
* これをnullに変換し、かつミリ秒を返すメソッド*/
export function nullableTs2Mil(ts: string) : number|null {
    if  (ts === "NaT") {
        return null
    }
    else {
        return Date.parse(ts)
    }
}

export function zero2null(s: number | undefined) : number | null {
    if (s === null || s === undefined) {
        return null
    }

    return s !== 0.0 ? s : null
}

import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    showProbes: false,
    showClusteredReserves: false,
    showDemands: false,
}

const mapAvailableSlice = createSlice({
    name: 'mapAvailable',
    initialState,
    reducers: {
        setShowClusteredReserves(state, action) {
            state.showClusteredReserves = action.payload
        },
        setShowProbes(state, action) {
            state.showProbes = action.payload
        },
        setShowDemands(state, action) {
            state.showDemands = action.payload
        },
    },
})

export const {setShowProbes, setShowClusteredReserves, setShowDemands} = mapAvailableSlice.actions
export default mapAvailableSlice.reducer

